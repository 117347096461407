
.Msmall-circle {
  height: auto;
  width: 60%;
  display: block;
  vertical-align:middle;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
}
