.bg-primary {
  background-color: $primary !important;
}

.btn-primary {
  background-color: $primary;
  border-color: $primary;
  &:active,
  &:focus,
  &:hover {
    background-color: darken($primary, 10%) !important;
    border-color: darken($primary, 10%) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem fade-out($primary, 0.5);
  }
}

.btn-secondary {
  background-color: $secondary;
  border-color: $secondary;
  &:active,
  &:focus,
  &:hover {
    background-color: darken($secondary, 10%) !important;
    border-color: darken($secondary, 10%) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem fade-out($secondary, 0.5);
  }
}

.btn-kofi {
  background-color: $kofi;
  border-color: $kofi;
  &:active,
  &:focus,
  &:hover {
    background-color: darken($kofi, 10%) !important;
    border-color: darken($kofi, 10%) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem fade-out($secondary, 0.5);
  }
}


.btn-wleague {
  background-color: $light-blue;
  border-color: $light-blue;
  color: white;
  &:active,
  &:focus,
  &:hover {
    background-color: darken($light-blue, 10%) !important;
    border-color: darken($light-blue, 10%) !important;
    color: white;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem fade-out($light-blue, 0.5);
  }
}


.btn-wwc {
  background-color: $purple;
  border-color: $purple;
  color: white;
  &:active,
  &:focus,
  &:hover {
    background-color: darken($purple, 10%) !important;
    border-color: darken($purple, 10%) !important;
    color: white;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem fade-out($purple, 0.5);
  }
}

.btn-aflw {
  background-color: $orange;
  border-color: $orange;
  color: white;
  &:active,
  &:focus,
  &:hover {
    background-color: darken($orange, 10%) !important;
    border-color: darken($orange, 10%) !important;
    color: white;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem fade-out($purple, 0.5);
  }
}

.btn-nwsl {
  background-color: $light-purple;
  border-color: $light-purple;
  color: white;
  &:active,
  &:focus,
  &:hover {
    background-color: darken($light-purple, 10%) !important;
    border-color: darken($light-purple, 10%) !important;
    color: white;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem fade-out($purple, 0.5);
  }
}

.btn-euro {
  background-color: $yellow-green;
  border-color: $yellow-green;
  color: white;
  &:active,
  &:focus,
  &:hover {
    background-color: darken($yellow-green, 10%) !important;
    border-color: darken($yellow-green, 10%) !important;
    color: white;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem fade-out($purple, 0.5);
  }
}

.btn-teams {
	background-color: $white;
	border-color: #4e579b;
	width: 100%;
	text-align: 'left';
}

.btn-banner {
        background-color: $white;
        border-color: #4e579b;
        width: 100%;
	height: 100px;
        text-align: 'left';
	background-image: url('/img/AusChi2-Kerr.jpg');
}

.btn-teams-wsl {
        background-color: $white;
        border-color: #008a5b;
        width: 100%;
        text-align: 'left';
}

.btn-teams-uwcl {
        background-color: $white;
        border-color: #008a5b;
        width: 100%;
        text-align: 'left';
}



.btn-alert {
  color: inherit;
}
