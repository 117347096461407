body {
  @include body-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include heading-font;
}

.btn-xl {
  text-transform: uppercase;
  padding: 1.5rem 3rem;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.btn-s {
  text-transform: uppercase;
  padding: 0rem 0rem;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.btn-m {
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  font-size: 1.0rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.btn-c {
  text-transform: uppercase;
  padding: 0.2rem 0.5rem;
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.btn-fixed-width {
  min-width: 220px; /* Adjust this value as needed */
  text-align: center;
}


.bg-black {
  background-color: $black !important;
}

.bg-image {
   background-image: linear-gradient( fade_out($dark, 0.5), fade_out($dark, 0.2) )
}

.rounded-pill {
  border-radius: 5rem;
}

.competition-wsl {
  a { color: $green;
 }
}

.competition-wwc {
  a { color: $purple;
  }
}

.competition-wl {
  a {color: $light-blue
  }
}


.competition-aflw {
  a {color: $orange
  }
}

.competition-uwcl {
  a {color: $light-blue
  }
}


.competition-nwsl {
  a {color: $light-purple
  }
}

h1 {
  margin-top: 1rem;
}
