
.responsive_league {
  width: auto;
  height: auto;
  max-height: 300px;
  max-width: 1000px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1%;
}


.responsive_league_multiple {
  width: auto;
  height: auto;
  max-height: 300px;
  max-width: 1000px;
  display: inline-block;
  border-radius: 1%;
}

.centre_div {
  text-align: center;
}
