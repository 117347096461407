
.responsive {
  width: 100%;
  height: auto;
  max-width: 1000px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1%;
}
