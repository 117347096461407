* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.bottom-nav {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  border-top: 1px solid rgb(230, 230, 230);
}
.bn-tab {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  border-bottom: solid 0px #fbf4cd;
}
.bottom-tab-nav {
  border-top: solid 2px #4e579b;
  background-color: white;
}
.a {
	color:#fbf4cd;
}
.nav-link,
.bottom-nav-link {
  color: #55575b;
}
.bottom-nav-link.active {
  color: #922c88;
}
.bottom-tab-label {
  font-size: 12px;
}
