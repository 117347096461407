// Core variables and mixins
@import "variables.scss";
@import "mixins.scss";

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
@import "../../node_modules/open-iconic/font/css/open-iconic-bootstrap.css";

// Global CSS
@import "global.scss";
// Components
@import "masthead.scss";
@import "bootstrap-overrides.scss";

@import "header.scss";

@import "mastheadWWC.scss";

@import "mastheadWWC2.scss";

@import "mastheadWSL.scss";

@import "mastheadNWSL.scss";

@import "mastheadEuro.scss";

@import "mastheadHome.scss";

@import "mastheadWL.scss";

@import "mastheadUWCL.scss";

@import "mastheadAFLW.scss";

@import "social.scss";

@import "responsive-image.scss";

@import "Mheader.scss";

@import "wrapper.scss";

@import "responsive-image-small.scss";

@import "responsive-image-large.scss";

@import "responsive-image-league.scss";

@import "responsive-image-medium.scss";

@import "responsive-image-2col.scss";

@import "centerBox.scss";

@import "leftBox.scss";

@import "column2.scss";

@import "row2.scss";

@import "banner.scss";

@import "bottomNav.scss";

@import "_small-circle";

@import "Msmall-circle.scss";

.country-flag {
    border: 1px solid $navy;
}

.dx-g-bs4-table {
    min-width: 50em !important;
    th:nth-child(2) {
        width: 30%;
    }
    .dx-g-bs4-fixed-cell {
        z-index: 5;
    }
}

@import "team.scss";

@import "competitions/wsl.scss";
@import "competitions/nwsl.scss";
@import "competitions/wwc.scss";
@import "competitions/wl.scss";
@import "competitions/euro.scss";
@import "competitions/uwcl.scss";
@import "competitions/aflw.scss";
@import "competitions/navlink.scss";
