
.responsive_medium {
  height: auto;
  width: 40%;
  max-width: 500px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 1%;
}
