// Variables
$fa-font-path: '/webfonts';
$icon-font-path: '/fonts';
// Restated Bootstrap Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$orange: #eb8951 !default;


$navy: #221f3f;
$blue-light: #65a2da;
$green: #008a5b;
$light-blue: #81b3e1;
$purple: #4e579b;
$kofi: #29ABE0;
$light-purple: #815EEB;
$yellow-green: #99BB44;

$primary: $blue-light;
$secondary: $green;
$dark: $navy;

$tertiary: $green !default;
$tertiary-dark: darken($dark, 20%)
