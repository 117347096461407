.header {
  background: url("/img/grass.svg") white bottom repeat-x;
  z-index: 1000;

  a {
    color: $navy;
    display: block;
    margin-bottom: 1rem;
    text-align: right;
    &:hover {
      text-decoration: none;
    }
  }
  h1 {
    font-family: "Lobster", "cursive";
    font-size: 4rem;
    margin-bottom: -0.2rem;
  }
  h2 {
    font-weight: 600 !important;
    font-family: "Lato";
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 800px) {
	.header{
		background: none;
  z-index: 1000;

  a {
    color: $navy;
    display: block;
    margin-bottom: -1.3rem;
    text-align: right;
    &:hover {
      text-decoration: none;
    }
  }
  h1 {
    font-family: "Lobster", "cursive";
    font-size: 0rem;
    margin-bottom: -0.2rem;
  }
  h2 {
    font-weight: 600 !important;
    font-family: "Lato";
    font-size: 0.0rem;
  }
}
}


.navbar-custom {
  z-index: 10;
  &.navbar-dark {
    background-color: $navy;
    z-index: 20;
  }

  button.nav-link.competition-dropdown.dropdown-toggle.btn.btn-secondary {
    border: solid white 1px !important;
    background-color: $navy !important;
    color: white;
    min-width: 16rem;
  }

  padding-top: 0.5em;
  padding-bottom: 0.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  .navbar-brand {
    @include heading-font;
    font-size: 2rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
  }
  .dropdown-menu{
	  position: absolute;
	  z-index: 2000;
  }
  .navbar-nav {
    .nav-item {
      margin-right: 0.5rem;
      .nav-link {
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 700;
        letter-spacing: 0.1rem;
        background: none;
        border: none;
        &:hover,
        &:focus,
        &:active {
          background: none !important;
          border: none !important;
          box-shadow: none;
        }
        &:active:focus {
          box-shadow: none;
        }
      }
      }
      .nav-item {
      margin-right: 0.5rem;
      .nav-link-white {
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 700;
	color: white;
        letter-spacing: 0.1rem;
        background: none;
        border: none;
        &:hover,
        &:focus,
        &:active {
          background: none !important;
          border: none !important;
          box-shadow: none;
        }
        &:active:focus {
          box-shadow: none;
        }
       
      }
    }
  }
}


.header-logo {
  height: 70px;
}

@media only screen and (max-width: 800px) {
	.navbar-custom {
  z-index: 10;
  &.navbar-dark {
    background-color: $navy;
    z-index: 20;
  }

  button.nav-link.competition-dropdown.dropdown-toggle.btn.btn-secondary {
    border: solid black 1px !important;
    background-color: rgba(white, 0.8) !important;
    color: black;
    min-width: 10rem;
  }

  button.nav-link.competition-dropdown-mobile.dropdown-toggle.btn.btn-secondary {
    border: solid white 1px !important;
    background-color: $navy !important;
    color: white;
    min-width: 8rem;
  }

    button.nav-link.competition-dropdown-mobile-wsl.dropdown-toggle.btn.btn-secondary {
    border: solid black 1px !important;
    background-color: $navy !important;
    color: black;
    min-width: 8rem;
  }


  padding-top: 0.5em;
  padding-bottom: 0.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  .navbar-brand {
    @include heading-font;
    font-size: 2rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
  }
  .navbar-nav {
    .nav-item {
      margin-right: 0.5rem;
      .nav-link {
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 700;
        letter-spacing: 0.1rem;
        background: none;
        border: none;
        &:hover,
        &:focus,
        &:active {
          background: none !important;
          border: none !important;
          box-shadow: none;
        }
        &:active:focus {
          box-shadow: none;
        }
      }
    }
  }
}
}
