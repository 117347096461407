header.banner {
  position: relative;
  overflow: hidden;
  padding-top: calc(0rem + 2px);
  padding-bottom: 7rem;
  background: linear-gradient( fade_out($dark, 0.9), fade_out($dark, 0.5) ), url('/img/sp7.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  .masthead-content {
    z-index: 1;
    position: relative;
    .masthead-heading {
      font-size: 4rem;
    }
    .masthead-subheading {
      font-size: 2rem;
    }
  }
}

@media (min-width: 992px) {
  header.banner {
    padding-top: calc(0rem + 5px);
    padding-bottom: 0rem;
    .masthead-content {
      p{
        font-size: 1.8rem;
        text-shadow: 1px 1px 5px black;

      }
      .masthead-heading {
        font-size: 6rem;
      }
    }
  }
}
