
.fa {
  padding: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: #3B5998;
  color: white;
  a {color: white;
  }
}

.fa-twitter {
  background: #55ACEE;
  a {color: white;
  }
}

.fa-instagram {
  background: #125688;
  a {color: white;
}
}
