header.mastheadEuro {
  position: relative;
  overflow: hidden;
  padding-top: calc(2rem + 92px);
  padding-bottom: calc(2rem + 92px);
  background: linear-gradient( fade_out($dark, 0.5), fade_out($dark, 0.2) ), url('/img/euro/euro5.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  .masthead-contentWL {
    z-index: 1;
    position: relative;
    .masthead-headingEuro {
      font-size: 2rem;
    }
    .masthead-subheadingEuro {
      font-size: 2rem;
    }
  }
}

@media (min-width: 992px) {
  header.mastheadWL {
    padding-top: calc(10rem + 55px);
    padding-bottom: 10rem;
    .masthead-contentEuro {
      p{
        font-size: 1.8rem;
        text-shadow: 1px 1px 5px black;

      }
      .masthead-headingEuro {
        font-size: 6rem;
      }
    }
  }
}
