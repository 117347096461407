.drag-handle {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    cursor: grab;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    width: initial;
    margin-right: 1rem;
    opacity: 0.5;
    &:hover,&:focus {
        opacity: 1;
    }
}
table {
    .injuredPlayer {
        background-color: #f8d7da;
    }
    .transferredPlayer {
        background-color: #fff3cd;
    }
}